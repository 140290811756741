import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import BootstrapVue3 from 'bootstrap-vue-3';
import store from "./state/store";
import vClickOutside from "click-outside-vue3"
import Maska from 'maska';
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";
// import '@/assets/scss/config/default/app.scss';
import '@/assets/scss/config/default/loan.scss';
import Loading from 'vue3-loading-screen';
// import { initFacebookSdk } from "@/utils/init-facebook-sdk";

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

//initFacebookSdk().then(startApp);
startApp();
function startApp(){
    createApp(App)
        .use(store)
        .use(router)
        .use(BootstrapVue3)
        .component(VueFeather.type, VueFeather)
        .use(Maska)
        .use(Particles)
        .use(i18n)
        .use(Loading, {
            bg: 'rgba(28,27,27,0.68)',
            icon: 'refresh',
            size: 3,
            icon_color: 'white',
            slot: `
        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" height="80" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"/>
            </path>
        </svg>`
        })
        .use(vClickOutside)
        .mount('#app')
}