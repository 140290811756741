import axios from "axios";
import Constants from "@/utils/constants";

const profile = async (jwt) => {
    axios.defaults.headers.common['authorization'] = 'Bearer ' + jwt;
    return await axios.get(`${Constants.SERVER_URL}/api/auth/profile`);
}

const getUserInfo = async (user_id) => {
    axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
    return await axios.post(`${Constants.SERVER_URL}/api/userinfo`, {user_id});
}

const changeName = async (name) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/changename`, {
            name
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

const changePassword = async (current_password, new_password, confirm_password) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/changepassword`, {
            current_password,
            new_password,
            confirm_password
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

const changeNameWithId = async (user_id, name) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/changeusername`, {
            user_id,
            name
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

const changeUserPassword = async (user_id, email, new_password, confirm_password) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/changeuserpassword`, {
            user_id,
            email,
            new_password,
            confirm_password
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}


const changeUserRole = async (user_id, role) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/changeuserrole`, {
            user_id,
            role
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default {
    profile,
    getUserInfo,
    changeName,
    changePassword,
    changeNameWithId,
    changeUserPassword,
    changeUserRole
}