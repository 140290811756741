import axios from "axios"
import LoanApi from "../../apis/loan-api"
import Constants from "../../utils/constants";
export const state = {
    loans : [],
    totalcurrentapprovalamount: 0,
    similarLoans: [],
    center: { lat: 40.689247, lng: -74.044502 }
};

export const getters = {
    loans: state => state.loans,
    totalcurrentapprovalamount: state => state.totalcurrentapprovalamount,
    similarLoans: state => state.similarLoans,
    center: state => state.center,
};

export const mutations = {
    GET_MAP_POSITION(state, result){
        state.center = result.data;
    },
    GET_LOANS_BY_NAME(state, result){
        state.loans = result.records;
        result.records.map((item) => {
            state.totalcurrentapprovalamount += parseFloat(item.currentapprovalamount);
        });
    },
    GET_SIMILAR_LOANS_BY_ZIP_BUSINESSTYPE(state, result){
        state.similarLoans = result.records;
    }
};

export const actions = {
    async getMapPosition({commit, dispatch}, address){
        const url = `${Constants.SERVER_URL}/api/search/location`;
        return await new Promise((resolve, reject) => {
             axios.post(url, {address})
                .then((result) => {
                    if (result.data.status === "errors"){
                        dispatch('alert/setAlert', { message: result.data.message, errors: null, color: 'danger' }, { root: true });
                        reject(result);
                    }else {
                        commit("GET_MAP_POSITION", result);
                        resolve(result);
                    }
                });
        })
    },
    async getLoansByName({commit}, req){
        const {borrowername, zipcode} = req;
         return await LoanApi.getLoansByName(borrowername, zipcode)
         .then((result) => {
             commit("GET_LOANS_BY_NAME", result);
         });
    },
    async getSimilarLoansByZipBusinessType({commit}, data){
        const { zip, type } = data;
        return await LoanApi.getSimilarLoansByZipBusinessType(zip, type)
            .then((result) => {
                commit("GET_SIMILAR_LOANS_BY_ZIP_BUSINESSTYPE", result);
            })
    }
};
