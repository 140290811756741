export const state = {
    selectedPath : ""
}

export const getters = {
    selectedPath: state => state.selectedPath,
}

export const mutations = {
    SELECT_PATH(state, path){
        state.selectedPath = path;
    }
}

export const actions = {
    selectPath({commit}, path){
        commit("SELECT_PATH", path);
    }
}
