import UtilFunc from "../../utils/functions";
import UserServices from "../../apis/users-services";

export const state = {
    formdata : {
        ascending: false,
        sortColumn: "",
        search: "",
    },
    columns: ['name', 'email', 'card_brand', 'card_exp_date', 'card_last4', 'paid'],
    users: [],
    pages: [],
    page: 1,
    perPageCnt: 10,
    totalCnt: 0,
    searchCnt: 0,
    totalPageCnt: 0,
}

export const getters = {
    formdata: state => state.formdata,
    columns: state => state.columns,
    users: (state) => {
        return state.users;
    },
    pages: state => state.pages,
    page: state => state.page,
    totalCnt: state => state.totalCnt,
}

export const mutations = {
    SORT_TABLE(state, col){
        if (state.formdata.sortColumn === col) {
            state.formdata.ascending = !state.formdata.ascending;
        } else {
            state.formdata.ascending = true;
            state.formdata.sortColumn = col;
        }

        let ascending = state.formdata.ascending;
        state.users.sort(function(a, b) {
            if (UtilFunc.toLowerCase(a[col]) > UtilFunc.toLowerCase(b[col])) {
                return ascending ? 1 : -1
            } else if (UtilFunc.toLowerCase(a[col]) < UtilFunc.toLowerCase(b[col])) {
                return ascending ? -1 : 1
            }
            return 0;
        })
    },
    GET_USERS(state, data){
        state.users = data.users;
        state.totalCnt = data.totalCnt;
        const pageCnt = state.totalCnt % state.perPageCnt === 0? state.totalCnt / state.perPageCnt : (state.totalCnt / state.perPageCnt) + 1;

        state.pages = [];
        for (let i = 1; i < pageCnt; i++) state.pages.push(i);

        state.page = data.pageNum;
    },

    SET_SEARCH_TEXT(state, val){
        state.formdata.search = val;
    }
}

export const actions = {

    sortTable({commit}, col){
        commit("SORT_TABLE", col);
    },

    async getUsers({commit}, pageNum){
        return new Promise((resolve, reject) => {
            UserServices.getUsers(pageNum, state.perPageCnt, state.formdata).then(result => {
                commit("GET_USERS", result.data);
                resolve();
            }).catch(errors => {
                commit('auth/LOG_OUT', null, { root: true });
                reject(errors);
            })
        })
    },

    async btnActionPrev({commit}) {
        state.page--;
        return new Promise((resolve, reject) => {
            UserServices.getUsers(state.page--, state.perPageCnt, state.formdata).then(result => {
                commit("GET_USERS", result.data);
                resolve();
            }).catch(errors => {
                commit('auth/LOG_OUT', null, { root: true });
                reject(errors);
            })
        })
    },

    async btnActionNext({commit}) {
        state.page++;
        return new Promise((resolve, reject) => {
            UserServices.getUsers(state.page++, state.perPageCnt, state.formdata).then(result => {
                commit("GET_USERS", result.data);
                resolve();
            }).catch(errors => {
                commit('auth/LOG_OUT', null, { root: true });
                reject(errors);
            })
        })
    },

    setSearchText({commit}, val){
        commit("SET_SEARCH_TEXT", val);
    }
}
