import axios from "axios";
import Constants from "@/utils/constants";

const search = async (formdata, pageNum = 1, perPageCnt = 10) => {
    const {state, zip_code, business_type, company_name, jobsreported, ascending, sortColumn, borroweraddress, loannumber } = formdata;
    const skip = (pageNum - 1) * perPageCnt;
    // let query_params = `?state=${state}&skip=${skip}&limit=${perPageCnt}&zipcode=${zip_code}&company_name=${company_name}&business_type=${business_type}&jobsreported=${jobsreported}&ascending=${ascending}&sortColumn=${sortColumn}&loannumber=${loannumber}&borroweraddress=${borroweraddress}`;
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.defaults.headers.common['x-access-token'] = Constants.TOKEN_KEY
        const res = await axios.post(`${Constants.SERVER_URL}/api/search`, {
            state,
            zip_code,
            business_type,
            company_name,
            jobsreported,
            ascending,
            sortColumn,
            borroweraddress,
            loannumber,
            skip,
            limit: perPageCnt
        });
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const getLoansByName = async (borrowername, zipcode) => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/search/loans_by_borrowername`, {borrowername, zipcode});
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const getSimilarLoansByZipBusinessType = async (zip, type) => {
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/search/similar_loans`, {zip, type});
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default {
    search,
    getLoansByName,
    getSimilarLoansByZipBusinessType
}