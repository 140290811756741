Number.prototype.format = function(n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};


const usDollar = (amount) => {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return USDollar.format(amount);
}

const tillDateFromDatetime = (datetime) => {
    let date = new Date(datetime);
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
}

const toDateString = (datetime) => {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(datetime)
    return [pad(d.getMonth()+1), pad(d.getDate()), d.getFullYear()].join('/')
}

const addCommaToNumber = (num) => {
    return num.toLocaleString();
}

const toLowerCase = (letters) => {
    return letters.toLowerCase();
}

const subString = (str, len) => {
    if (str.length <= len)
        return `${str.substring(0, len)}`;
    else
        return `${str.substring(0, len)} ...`;
}

export default {
    tillDateFromDatetime,
    toDateString,
    addCommaToNumber,
    toLowerCase,
    subString,
    usDollar
}