import axios from "axios";
import Constants from "@/utils/constants";

const getUsers = async (pageNum, perPageCnt, formdata) => {
    const {search} = formdata;
    try {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        const res = await axios.post(`${Constants.SERVER_URL}/api/getusers`, {
            pageNum,
            perPageCnt,
            search
        });
        return Promise.resolve(res.data);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

const getUserById = async (user_id) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
        axios.post(`${Constants.SERVER_URL}/api/getuser`, {
            user_id
        }).then(response => {
            resolve(response.data);
        }).catch(error => {
            reject(error);
        })
    })
}

export default {
    getUsers,
    getUserById
}