import UsersServices from "@/apis/users-services";
import router from "@/router";
import ProfileServices from "@/apis/profile-services";

export const state = {
    selectedUser: {
        name: "",
        email: "",
        role: "",
        password: "",
    },
    user: {
        id: 0,
        name: "",
        email: "",
        role: "",
        new_password: "",
        confirm_password: "",
    },

    edit_enabled: "",
}

export const getters = {
    user: state => state.user,
    selectedUser: state => state.selectedUser,
    edit_enabled: state => state.edit_enabled,
}

export const mutations = {
    SET_USER(state, user){
        state.selectedUser = JSON.parse(JSON.stringify(user)); // JSON prevents to sync selectedUser and user
        state.user = JSON.parse(JSON.stringify(user));
    },
    SET_NAME_TEXT(state, name){
        state.user.name = name;
    },

    SET_NEW_PASSWORD_TEXT(state, new_password){
        state.user.new_password = new_password;
    },

    SET_CONFIRM_PASSWORD(state, confirm_password){
      state.user.confirm_password = confirm_password;
    },

    SET_ROLE(state, role){
        state.user.role = role;
    },

    SET_EDIT_ENABLED(state, type){
        state.edit_enabled = type;
    }
}

export const actions = {
    setUser({commit, dispatch}, user_id){
        return UsersServices.getUserById(user_id)
            .then(response => {
                if (response.status === "success"){
                    const user = response.data;
                    commit("SET_USER", user);
                }else {
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'danger' }, { root: true });
                }
            })
            .catch(error => {
                let message = error.response.message || error.message
                let errors = error.response.errors
                dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                commit('auth/LOG_OUT', null, { root: true });
                router.push({name: 'home'});
            })
    },

    setNameText({commit}, name){
        commit("SET_NAME_TEXT", name);
    },

    setNewPasswordText({commit}, new_password){
        commit("SET_NEW_PASSWORD_TEXT", new_password);
    },

    setConfirmPasswordText({commit}, confirm_password){
        commit("SET_CONFIRM_PASSWORD", confirm_password);
    },

    setEditEnabled({commit}, type){
        commit("SET_EDIT_ENABLED", type);
    },

    setRole({commit}, role){
        commit("SET_ROLE", role);
    },

    async changeName({commit, state, dispatch}){
        const {id, name} = state.user;
        return await ProfileServices.changeNameWithId(id, name)
            .then(response => {
                const {status, data, message} = response;
                if (status === "success"){
                    dispatch('alert/setAlert', { message: message, errors: null, color: 'success' }, { root: true });
                    commit("SET_USER", data);
                    commit("SET_EDIT_ENABLED", "");
                }else {
                    dispatch('alert/setAlert', { message: message, errors: null, color: 'danger' }, { root: true });
                }
            })
            .catch(error => {
                let message = error.response.message || error.message
                let errors = error.response.errors
                dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                commit('auth/LOG_OUT', null, { root: true });
                router.push({name: 'home'});
            });
    },

    async changeUserPassword({commit, state, dispatch}){
        const {id, email, new_password, confirm_password} = state.user;
        return await ProfileServices.changeUserPassword(id, email, new_password, confirm_password)
            .then(response => {
                if (response.status === "success"){
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'success' }, { root: true });
                    commit("SET_EDIT_ENABLED", "");
                }else {
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'danger' }, { root: true });
                }
            }).catch(error => {
                let message = error.response.message || error.message
                let errors = error.response.errors
                dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                commit('auth/LOG_OUT', null, { root: true });
                router.push({name: 'home'});
            })
    },

    async changeUserRole({commit, dispatch}){
        const {id, role} = state.user;
        return await ProfileServices.changeUserRole(id, role)
            .then(response => {
                if (response.status === "success"){
                    const user = response.data;
                    commit("SET_USER", user);
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'success' }, { root: true });
                    commit("SET_EDIT_ENABLED", "");
                }else {
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'danger' }, { root: true });
                }
            }).catch(error => {
                let message = error.response.message || error.message
                let errors = error.response.errors
                dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                commit('auth/LOG_OUT', null, { root: true });
                router.push({name: 'home'});
            })
    }
}