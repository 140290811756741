const SERVER_URL = process.env.VUE_APP_API_URL;
const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY || 'AIzaSyATD6C5HPaQ71YYOrIORgm6NdYHYrkjpsc';
const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;

const ROLE = {
    ADMIN: "admin",
    USER: "USER"
}

export default {
    SERVER_URL,
    GOOGLE_MAP_API_KEY,
    TOKEN_KEY,
    ROLE
}
