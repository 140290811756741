import PageNotFound from "../views/errors/404-cover.vue";
import store from "@/state/store";
import Constants from "@/utils/constants"
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/login'),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/pages/signup'),
    meta: {
      title: "Signup",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/registration',
    name: 'registration',
    meta: { title: "Registration", authRequired: false },
    component: () => import('../views/pages/registration'),
  },
  {
    path: '/',
    name: 'home',
    meta: { title: "Home Basic Search", authRequired: false },
    component: () => import('../views/pages/home-basic-search'),
    beforeEnter: (to, from, next) => {
      const loggedIn = store.getters["auth/loggedIn"];
      if (loggedIn){
        const login = store.getters["auth/currentUser"];
        const {role} = login;
        if (Constants.ROLE.ADMIN === role)
          next({name: "AdminUsers"});
        else
          next();
      }else{
        next();
      }
    }
  },
  {
    path: '/:borrowername/:zipcode',
    name: 'detail',
    meta: { title: "Company Detail", authRequired: false },
    component: () => import('../views/pages/detail')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: "Profile", authRequired: true,
    },
    component: () => import('../views/pages/profile/profile'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    props: true,
    meta: {
      title: "Checkout", authRequired: true,
    },
    component: () => import('../views/pages/stripe/stripe-checkout')
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      title: "Admin", authRequired: true,
    },
    component: () => import('../views/pages/admin'),
    children: [
      {
        path: '/admin/dashboard',
        name: 'Dashboard',
        meta: {
          title: "AdminUsers", authRequired: true,
        },
        component: () => import('../views/pages/admin/dashboard')
      },
      {
        path: '/admin/users',
        name: 'AdminUsers',
        meta: {
          title: "AdminUsers", authRequired: true,
        },
        component: () => import('../views/pages/admin/users')
      },
      {
        path: '/admin/userdetail',
        name: 'userdetail',
        meta: {
          title: "UserDetail", authRequired: true,
        },
        component: () => import('../views/pages/admin/userdetail/userdetail')
      },
    ]
  },

  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    meta: {
      title: "Privacy Policy", authRequired: false,
    },
    component: () => import('../views/pages/privacy-policy'),
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    meta: {
      title: "Terms Of Service", authRequired: false,
    },
    component: () => import('../views/pages/terms-of-service'),
  },
  {
    path: '/error500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: false,
    },
    component: () => import('../views/errors/500')
  },
  {
    path: '/oauth/error',
    name: 'oauth',
    meta: {
      title: "Error Oauth", authRequired: false,
    },
    component: () => import('../views/errors/oauth-error')
  },
  {
    path: '/:catchAll(.*)*',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: false,
    },
    component: PageNotFound
  },
];