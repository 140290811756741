import ProfileServices from "../../apis/profile-services"
import router from "@/router";

export const state = {
    user: {
        name: "",
        avatar: "",
        email: "",
        current_password: "",
        password: "",
        confirm_password: "",
    },
    edit_enabled: "",
    subscription: null
}

export const getters = {
    subscription(state){
        return state.subscription;
    },
    edit_enabled: state => state.edit_enabled,
    user: state => state.user,
}

export const mutations = {
    SET_USERINFO(state, userinfo){
        state.subscription = userinfo;
    },

    SET_EDIT_ENABLED(state, type){
        state.edit_enabled = type;
    },

    SET_NAME_TEXT(state, text){
        state.user.name = text;
    },

    SET_PASSWORD_TEXT(state, text){
        state.user.password = text;
    },

    SET_CONFIRM_PASSWORD_TEXT(state, text){
        state.user.confirm_password = text;
    },

    SET_CURRENT_PASSWORD_TEXT(state, text){
        state.user.current_password = text;
    }
}

export const actions = {
    async getUserInfo({commit}, user_id){
        const result = await ProfileServices.getUserInfo(user_id);
        if (result){
            commit("SET_USERINFO", result.userinfo);
        }
    },

    setEditEnabled({commit}, type){
        commit("SET_EDIT_ENABLED", type);
    },

    setNameText({commit}, text){
        commit("SET_NAME_TEXT", text);
    },

    setPasswordText({commit}, text){
        commit("SET_PASSWORD_TEXT", text);
    },

    setConfirmPasswordText({commit}, text){
        commit("SET_CONFIRM_PASSWORD_TEXT", text);
    },

    setCurrentPasswordText({commit}, text){
        commit("SET_CURRENT_PASSWORD_TEXT", text);
    },

    setCurrentUser({commit}){
        const currentUser = JSON.parse(localStorage.getItem('userdata'));
        commit("SET_NAME_TEXT", currentUser.name);
    },

    async changeName({commit, state, dispatch}){ // You can add rootState to get currentUser in auth module
        const {name} = state.user;
        //const {id} = rootState.auth.currentUser;
        return new Promise((resolve, reject) => {
            ProfileServices.changeName(name)
                .then(response => {
                    dispatch('alert/setAlert', { message: response.message, errors: null, color: 'success' }, { root: true });
                    const user = response.data;
                    dispatch('setCurrentUser');
                    dispatch('auth/setCurrentUser', user, {root: true});
                    commit("SET_EDIT_ENABLED", "");
                    resolve(response.data);
                })
                .catch(error => {
                    let message = error.response.message || error.message
                    let errors = error.response.errors
                    dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                    commit('auth/LOG_OUT', null, { root: true });
                    router.push({name: 'home'});
                    reject(error);
                })
        })
    },

    async changePassword({commit, state, dispatch}){
        const {current_password, password, confirm_password} = state.user;
        return new Promise((resolve, reject) => {
            ProfileServices.changePassword(current_password, password, confirm_password)
                .then(response => {
                    if (response.status === "success"){
                        dispatch('alert/setAlert', { message: response.message, errors: null, color: 'success' }, { root: true });
                        commit("SET_EDIT_ENABLED", "");
                    }else {
                        dispatch('alert/setAlert', { message: response.message, errors: null, color: 'danger' }, { root: true });
                    }
                    resolve(response.data);
                }).catch(error => {
                    let message = error.response.message || error.message
                    let errors = error.response.errors
                    dispatch('alert/setAlert', { message: message, errors: errors, color: 'danger' }, { root: true })
                    commit('auth/LOG_OUT', null, { root: true });
                    router.push({name: 'home'});
                    reject(error);
                })
        })
    }
}